import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "./App.css";
import Portal from "./pages/Portal/portal";
import CodePurchase from "./pages/CodePurchase/CodePurchase";
import FirstPage from "./pages/FirstPage/FirstPage";

function App() {
  return (
    <div className="max-w-[1600px] mx-auto">
      <Router>
        <Routes>
          <Route path="/" element={<FirstPage />} />
          <Route path="/register" element={<Portal />} />
          <Route path="/code-purchase" element={<CodePurchase />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
