import React from "react";
import successLogo from "../assets/success.svg";

const CodeGenerateSuccess = ({ isLoading, showSuccess }) => {
  return (
    <div
      className={`${
        showSuccess ? "block" : "hidden"
      } fixed inset-0 z-50 top-0 bottom-0 flex flex-col items-center justify-center py-2`}
    >
      <div className="fixed inset-0 bg-black opacity-70"></div>
      <div
        className={`z-50 bg-white py-10 w-[95%] lg:w-[430px] px-4 lg:px-[82px]`}
      >
        <div className="flex justify-center w-full">
          <img src={successLogo} alt="" className="w-[110px] h-[110px]" />
        </div>
        <div className="text-center mt-[35px]">
          <p className="text-lg font-[700]">Great!!!</p>
          <p className="text-sm text-[#A3A3A3]">
            Codes generated successfully.
          </p>
        </div>
          <button
            className="disabled:bg-laGrey disabled:cursor-not-allowed bg-[#232F84] hover:opacity-90 text-white py-[18px] w-full text-base mt-[57px]"
            disabled={isLoading}
            onClick={()=>window.location.reload()}
          >
            Click to continue
          </button>
      </div>
    </div>
  );
};

export default CodeGenerateSuccess;
