import React, { useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";
import { HiMiniLockClosed } from "react-icons/hi2";
import CodeGenerateSuccess from "../../components/CodeGenerateSuccess";
import { useEffect } from "react";


const CodePurchase = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [listForCodes, setListForCode] = useState([])
  const [selectedItems, setSelectedItems] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);


  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = JSON.parse(localStorage.getItem("portal"));
  const email = userData?.email;
//   console.log(email)

  useEffect(()=> {
    codeList()    
  }, [])

  useEffect(()=> {
        if (!email) {
          window.location.href = "/portal/register";
        }
  })

  const handleCheckboxChange = (item) => {
    const itemIndex = selectedItems.findIndex(
      (selected) => selected.id === item.id
    );
    if (itemIndex === -1) {
      // If the item is not already in selectedItems, add it
      setSelectedItems([...selectedItems, { ...item, quantity: 1 }]);
    } else {
      // If the item is already in selectedItems, remove it
      const updatedItems = [...selectedItems];
      updatedItems.splice(itemIndex, 1);
      setSelectedItems(updatedItems);
    }
  };

  const handleQuantityChange = (item, quantity) => {
    const updatedItems = [...selectedItems];
    const itemIndex = updatedItems.findIndex(
      (selected) => selected.id === item.id
    );

    if (itemIndex !== -1) {
      updatedItems[itemIndex].quantity = quantity;
      setSelectedItems(updatedItems);
    }
  };

  function getQuantitiesInOrder(selectedItems) {
    return selectedItems.map((item) =>
      item.quantity ? parseInt(item.quantity) : 0
    );
  }

  function getItemIdsInOrder(selectedItems) {
    return selectedItems.map((item) => (item.id ? parseInt(item.id) : 0));
  }

  function calculateTotalPrice() {
    return selectedItems.reduce((total, item) => {
      const quantity = item.quantity ? parseInt(item.quantity) : 0;
      const price = item.price ? parseFloat(item.price) : 0;
      return total + quantity * price;
    }, 0);
  }

  function formatPrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const quantities = getQuantitiesInOrder(selectedItems);
  const selected_code_types = getItemIdsInOrder(selectedItems);
//   console.log(quantities);
//   console.log(selected_code_types);
//   console.log(selectedItems);

  const amountNumber = parseInt(calculateTotalPrice());
  //   console.log(amountNumber);

  const config = {
    reference: new Date().getTime().toString(),
    email,
    amount: amountNumber * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
  };

  const onSuccess = (transaction) => {
    // console.log(transaction);

    // Calling the subscribe endpoint after the payment has been successful
    fetch(`${baseUrl}/portal/generate-codes-send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({
        selected_code_types,
        quantities,
        email,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the subscription success response
        // console.log("Code generated:", data);
        if (data) {
          setShowSuccess(true);
        //   localStorage.removeItem("generateCode");
        }
      })
      .catch((error) => {
        // Handle subscription error
        console.error("Code generate failed:", error);
      });
  };

  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
   return toast.error("Payment canceled!");
  };

  const initializePayment = usePaystackPayment(config);

    const codeList = async () => {
      try {
        const response = await fetch(`${baseUrl}/portal/list-code-types`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          toast.error("Unable to get Code List!") }

        const data = await response.json();

        // console.log(data);
        setListForCode(data.codeTypes)
        return data;
      } catch (error) {
        toast.error("Error creating account:", error, {
          hideProgressBar: false,
          progress: undefined,
        });
        setIsLoading(false);
      }
    };

  return (
    <>
      <CodeGenerateSuccess showSuccess={showSuccess} />

      <div className="w-full min-h-screen bg-[#F7F8FA] lg:p-[50px]">
        <div className="ml-[24px] flex flex-col gap-5">
          <div className="flex gap-4 items-center">
            <img src="/learnAfrica.svg" alt="" className="h-[50px] w-[50px]" />
            <p className="font-[700] text-[#232F84] text-[38px]">LearnAfrica</p>
          </div>
          <p className="text-lg text-[#303030]">
            Web portal for code generation
          </p>
        </div>

        <div className="bg-white w-full min-h-[calc(100vh-220px)] mt-[18px] px-[56px] py-[30px]">
          <p className="font-[700] text-2xl text-[#303030]">
            Select Codes to Purchase{" "}
          </p>

          <div className="flex">
            {/* Selection of codes */}
            <div className="flex-1 mt-10 flex flex-col gap-10">
              {listForCodes && listForCodes.map((item, index) => (
                <div
                  key={item.id}
                  className="flex items-center space-x-5 text-[#A3A3A3] text-sm"
                >
                  <input
                    type="checkbox"
                    checked={selectedItems.some(
                      (selected) => selected.id === item.id
                    )}
                    className="form-checkbox h-4 w-4 text-[#232F84]"
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <label className="text-base w-20">{item.name}</label>
                  <input
                    type="number"
                    className="w-[77px] h-[34px] border outline-none py-2.5 px-2 text-center"
                    placeholder="Qty"
                    value={
                      selectedItems.find((selected) => selected.id === item.id)
                        ?.quantity || ""
                    }
                    onChange={(e) => handleQuantityChange(item, e.target.value)}
                  />
                </div>
              ))}
            </div>

            {/* Display of selected Items */}
            <div className="flex-1">
              <div className="w-[390px] min-h-[calc(100vh-320px)] flex flex-col">
                <p className="font-[700] text-2xl text-[#303030]">
                  Selected Items
                </p>
                <div className="my-6 flex flex-col gap-4">
                  {selectedItems &&
                    selectedItems.map((item) => (
                      <div key={item.id}>
                        <div className="rounded-2xl border border-[#EFEFEF] w-[332px] p-5 flex flex-col gap-1">
                          <p className="text-lg font-bold">
                            {item.name} ({item.quantity}{" "}
                            {item.quantity > 1 ? "units" : "unit"})
                          </p>
                          <p className="text-[#B8B8B8]">{item.description}</p>
                          <p className="text-[#B8B8B8]">
                            <span className="text-black font-bold">
                              ₦{item.price}
                            </span>
                            /per student for a year
                          </p>
                          <p className="font-bold">
                            Sub Total: ₦{(item.quantity || 1) * item.price}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="flex justify-between font-bold text-2xl mt-auto">
                  {selectedItems.length >= 1 && <p>Total Price:</p>}
                  {selectedItems.length >= 1 && (
                    <p className="text-3xl">
                      ₦{formatPrice(calculateTotalPrice())}
                    </p>
                  )}
                </div>

                {selectedItems.length >= 1 && (
                  <div className="flex flex-col items-center gap-4 text-white text-sm mt-6">
                    <button
                      className="w-[390px] h-[45px] bg-[#232F84] hover:opacity-90 disabled:bg-gray-400 disabled:cursor-not-allowed transition"
                      onClick={() => {
                        initializePayment(onSuccess, onClose);
                      }}
                      disabled={selectedItems.length === 0}
                    >
                      Make Purchase
                    </button>
                    <button className="h-10 w-[183px] font-thin bg-black cursor-default flex justify-center items-center gap-1.5">
                     <HiMiniLockClosed /> Secured by <span className="font-bold">paystack</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CodePurchase;
