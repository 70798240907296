import React from 'react'
import { useEffect } from 'react'

const FirstPage = () => {
    useEffect(()=> {
        window.location.href ='/register'
    },[])
  return (
    <div></div>
  )
}

export default FirstPage