import React, { useEffect, useState } from "react";
import books from "../assets/books.png";
import lens from "../assets/lens.png";
import bulb from "../assets/bulb.png";

const Slider = () => {
  const slideData = [
    {
      id: 1,
      title: "Welcome to Learn Africa",
      image: books,
      desc: "Your ultimate AI-powered learning companion! We're here to transform your learning experience.",
    },
    {
      id: 2,
      title: "Explore Subjects and Topics",
      image: lens,
      desc: "Choose from an extensive collection of subjects and topics carefully designed to align with your learning goals.",
    },
    {
      id: 3,
      title: "Personalised Learning Journey",
      image: bulb,
      desc: "Ask questions naturally, receive detailed explanations, and unlock a personalised learning journey that adapts to your pace and style.",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () =>
        setCurrentSlide((prev) =>
          prev === slideData.length - 1 ? 0 : prev + 1
        ),
      4000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center lg:items-start gap-10 lg:h-[100vh] px-4 lg:px-0 pt-[30px] lg:pt-[100px] lg:pl-[195px]">
      <div>
        <div className="flex gap-5 items-center justify-center lg:justify-start">
          <img src="learnAfrica.svg" alt="" />
          <h1 className="text-[38px] font-[700] text-laBlue">LearnAfrica</h1>
        </div>
        <p className="max-w-[423px] text-dark text-lg leading-tight mt-[18px] text-center lg:text-start">
          Your ultimate AI-powered learning companion! We're here to transform
          your learning experience.
        </p>
      </div>
      <div className="w-[300px] h-[300px] relative flex justify-center">
        <img
          src={slideData[currentSlide].image}
          alt=""
          className="object-cover w-full h-full duration-300"
        />
        <div className="absolute -bottom-7 flex gap-2">
          <div
            className={`rounded-full w-4 h-4 mt-[10px] transition-all ${
              currentSlide === 0
                ? "bg-[#FFDD00] duration-300 transition-all"
                : "bg-[#D9D9D9]"
            }`}
          ></div>
          <div
            className={`rounded-full w-4 h-4 transition-all ${
              currentSlide === 1
                ? "bg-[#FFDD00] duration-300 transition-all"
                : "bg-[#D9D9D9]"
            }`}
          ></div>
          <div
            className={`rounded-full w-4 h-4 mt-[10px] transition-all ${
              currentSlide === 2 ? "bg-[#FFDD00] duration-300" : "bg-[#D9D9D9]"
            }`}
          ></div>
        </div>
      </div>
      <div className="max-w-[310px]">
        <h3 className="text-[#020003] text-lg font-[700] text-center">
          {slideData[currentSlide].title}
        </h3>
        <p className="text-[#A3A3A3] text-sm font-[400] mt-[13px] text-center">
          {slideData[currentSlide].desc}
        </p>
      </div>
    </div>
  );
};

export default Slider;
