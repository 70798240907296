import React from 'react'
import Onboarding from '../../components/Onboarding';
import { Link } from 'react-router-dom';
import RegisterForm from '../../components/RegisterForm';

const Portal = () => {
  return (
    <div>
      <Onboarding>
        <div className="lg:w-643px px-4 md:px-8 py-4 lg:px-[127px] lg:h-full bg-white flex flex-col justify-center shadow-md">
          {/* Register Form */}

            <div>
              <RegisterForm
              />
            </div>
        </div>
      </Onboarding>
    </div>
  );
}

export default Portal