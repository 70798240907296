import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import RegisterSuccess from "./RegisterSuccess";

const RegisterForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false)

  const navigate = useNavigate();

  const schema = z.object({
    email: z.string().email({ message: "Please use a valid email" }),
    first_name: z
      .string()
      .min(4, { message: "First name must be at least 4 characters long" }),
    last_name: z
      .string()
      .min(4, { message: "Last name must be at least 4 characters long" }),
    school_name: z
      .string()
      .min(3, { message: "School name must be at least 3 characters long" }),
    phone_number: z
      .string()
      .min(10, { message: "Phone number must be atleast 10 digits long" })
      .refine((value) => /^\d{10,15}$/.test(value), {
        message: "Please enter a valid phone number",
      }),
  });

  const {
    register,
    handleSubmit,
    formState,
    watch,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const baseUrl = process.env.REACT_APP_BASE_URL;

  watch();

  const submitForm = async (data) => {

    // return alert(JSON.stringify(data, null, 2))

    const result = await createAccount(data);
    // console.log("result is " + JSON.stringify(result, null, 2));
    if (result.errors) {
      toast.error(`${result.message}`, {
        hideProgressBar: false,
        progress: undefined,
      });
    } else {
      setShowSuccess(true);
      localStorage.setItem("portal", JSON.stringify({ email: data.email }));
    }
    setIsLoading(false);
  };

  // watch();


  const createAccount = async (userData) => {
    try {
      const response = await fetch(`${baseUrl}/portal/create-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        toast.error("Something went wrong, all fields are required!", {
          hideProgressBar: false,
          progress: undefined,
        });
      }

      const data = await response.json();

      console.log(data);
      return data;
    } catch (error) {
      toast.error("Error creating account:", error, {
        hideProgressBar: false,
        progress: undefined,
      });
      setIsLoading(false);
    }
  };


  return (
    <>
    <RegisterSuccess showSuccess={showSuccess} />
      <div className="px-4">
        <div className="flex gap-2">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-dark text-center text-2xl font-[700]">
              Create an account
            </h2>
            <p className="text-gray-500 text-sm text-center font-[400]">
              Please enter your profile.
            </p>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(submitForm)}
          noValidate
          className="flex flex-col gap-4"
        >
          <div className="flex flex-col w-full">
            <label
              htmlFor="first_name"
              className="text-[#474747] font-[400] mb-2 text-xs"
            >
              First name
            </label>
            <input
              type="text"
              className="border-[1px] border-gray-300 h-[43px] w-full outline-none px-3 placeholder:text-sm"
              name="first_name"
              // value={userDetails.fname}
              // onChange={handleInputChange}
              {...register("first_name")}
              placeholder="Enter first name"
              // defaultValue={"Abdullah Oyewale"}
            />
            <p className="text-xs text-red-600 mt-1">
              {errors.first_name?.message}
            </p>
          </div>

          <div className="flex flex-col w-full">
            <label
              htmlFor="last_name"
              className="text-[#474747] font-[400] mb-2 text-xs"
            >
              Last name
            </label>
            <input
              type="text"
              className="border-[1px] border-gray-300 h-[43px] w-full outline-none px-3 placeholder:text-sm"
              name="last_name"
              // value={userDetails.fname}
              // onChange={handleInputChange}
              {...register("last_name")}
              placeholder="Enter last name"
              // defaultValue={"Abdullah Oyewale"}
            />
            <p className="text-xs text-red-600 mt-1">
              {errors.last_name?.message}
            </p>
          </div>

          <div className="flex flex-col w-full">
            <label
              htmlFor="school_name"
              className="text-[#474747] font-[400] mb-2 text-xs"
            >
              School name
            </label>
            <input
              type="text"
              className="border-[1px] border-gray-300 h-[43px] w-full outline-none px-3 placeholder:text-sm"
              name="school_name"
              {...register("school_name")}
              // value={userDetails.school_name}
              // onChange={handleInputChange}
              placeholder="Enter school name"
              // defaultValue={"abdullah"}
            />
            <p className="text-xs text-red-600 mt-1">
              {errors.school_name?.message}
            </p>
          </div>

          <div className="flex flex-col w-full">
            <label
              htmlFor="phone_number"
              className="text-[#474747] font-[400] mb-2 text-xs"
            >
              Phone number
            </label>
            <input
              type="number"
              className="border-[1px] border-gray-300 h-[43px] w-full outline-none px-3 placeholder:text-sm"
              name="phone_number"
              {...register("phone_number")}
              placeholder="Enter phone number"
              // defaultValue={"08065654682"}
            />
            <p className="text-xs text-red-600 mt-1">
              {errors.phone_number?.message}
            </p>
          </div>

          <div className="flex flex-col w-full">
            <label
              htmlFor="email"
              className="text-[#474747] font-[400] mb-2 text-xs"
            >
              Email
            </label>
            <input
              type="email"
              className="border-[1px] border-gray-300 h-[43px] w-full outline-none px-3 placeholder:text-sm"
              name="email"
              {...register("email")}
              placeholder="Enter email address"
              // defaultValue={"08065654682"}
            />
            <p className="text-xs text-red-600 mt-1">{errors.email?.message}</p>
          </div>

          <button
            //   type="button"
            className="bg-[#232F84] text-white py-[10px] mt-[30px] mb-6 disabled:bg-laGrey disabled:cursor-not-allowed"
            disabled={isLoading}
          >
            Create Account
          </button>
        </form>
      </div>
    </>
  );
};

export default RegisterForm;
