import React from "react";
import Slider from "../components/Slider";

const Onboarding = ({ children }) => {
  return (
    <div className="flex flex-col lg:flex-row h-[100vh] bg-[#F7F8FA]">
      <div className="lg:w-[50%]">
        <Slider />
      </div>
      <div className="lg:w-[50%] lg:pr-[50px] py-[40px] px-3">{children}</div>
    </div>
  );
};

export default Onboarding;
